import { head } from "lodash-es";
import { ContactDbWidget } from "../../api/contacts/contacts.typings";
import { ContactOutputDto } from "../typings/contacts.typings";

export const mapContactDtoToContactOutputDto = (contact: ContactDbWidget): ContactOutputDto => ({
  id: contact?.id,
  firstname: contact?.givenName,
  lastname: contact?.name,
  fullName: contact?.fullName,
  email: head(contact?.emails)?.value,
  scope: {
    isInternal: contact?.isInternal,
    isIcUser: contact?.isIcUser,
    isSgGroupContact: contact?.isSgGroupContact,
    isInMyPortfolio: contact?.isInMyPortfolio,
    isActive: contact?.isActive,
  },
  hashtags: contact?.hashtags,
  icId: contact?.employeeOfAccountId,
  bdrCommercialId: contact?.employeeOf?.bdrCommercial?.id,
  bdrCommercialLevel: contact?.employeeOf?.bdrCommercial?.level,
  bdrLegalId: contact?.employeeOf?.bdrLegal?.id,
  name: contact?.employeeOf?.name,
  bdrLegalLevel: contact?.employeeOf?.bdrLegal?.level,
  isPepOrSpo: contact?.isPepOrSpo ?? false,
  country: {
    iso3: contact?.country?.iso3,
    name: contact?.country?.name,
  },
  jobTitle: contact?.jobTitle,
  team: contact?.internalHrTeam,
  igg: contact?.igg,
  bdrMnemonic: undefined, //not available in sgm-contact api
  rctId: undefined, //not available in sgm-contact api
});
