import { ContactDbWidget } from "../../api/contacts/contacts.typings";
import * as React from "react";
import { BulletValue } from "./BulletValue/BulletValue";
import { ChildrenRenderProps } from "@sgbs-ui/core";
import { ContactDisplayOption } from "../typings";
import { formatAccountName } from "../mappers/account.mapper";
import { ContactQualityView } from "@ic-anywhere/ic-dal";
import { ContactIndicatorsTooltip } from "@ic-anywhere/ic-components";

export function renderContact(displayOption: ContactDisplayOption, contactQuality?: ContactQualityView) {
  // eslint-disable-next-line react/display-name
  return (
    { fullName, jobTitle, emails, employeeOf, id }: ContactDbWidget,
    { withHighlight, isReadOnly }: ChildrenRenderProps
  ): React.ReactElement => {
    const email = emails && emails?.length > 0 ? emails[0]?.value : null;
    const valueClassName = "text-small";
    return (
      <span className="d-flex align-items-center">
        {withHighlight(fullName ?? "")}
        {contactQuality && (
          <span style={{ maxHeight: "20px" }}>
            <ContactIndicatorsTooltip displayMode={"image"} contactQualityIndicators={contactQuality} className={"ml-2"} />
          </span>
        )}
        {employeeOf?.name && (
          <BulletValue isReadOnly={isReadOnly} className={valueClassName}>
            {formatAccountName(employeeOf?.name, employeeOf?.bdrLegal?.level)}
          </BulletValue>
        )}
        {displayOption === "email" && email && (
          <BulletValue isReadOnly={isReadOnly} className={valueClassName}>
            {withHighlight(email)}
          </BulletValue>
        )}
        {displayOption === "jobtitle" && (
          <BulletValue value={jobTitle} isReadOnly={isReadOnly} className={valueClassName} />
        )}
      </span>
    );
  };
}
